.aboutUs {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  justify-content: center;
}

.aboutUsContainer {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 6rem 0 4rem 0;
}

.about-us h4 {
  font-size: 32px;
  margin: 1rem 0;
}

.about-us p {
  /* font-family: "Playwrite DE Grund", cursive; */
  line-height: 1.8;
  font-size: 20px;
}

.why-us {
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
}

.why-us h4 {
  margin-bottom: 2.5rem;
  font-size: 32px;
}

.why-us h6 {
  font-size: 20px;
  font-weight: 700;
  text-decoration: underline;
}

.why-us-section {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}

.why-us-section p {
  line-height: 1.8;
  font-size: 20px;
  width: 100%;
}

.why-us-section img {
  margin-left: 50px;
}
.community {
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
}

.community h4 {
  margin-bottom: 1rem;
  font-size: 32px;
}

.community p {
  /* font-family: "Playwrite DE Grund", cursive; */
  line-height: 2;
  font-size: 20px;
}

.followUs {
  margin: 6rem 0 2rem 0;
  text-align: center;
}

.about-founder {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  
}

.about-founder h4 {
  font-size: 32px;
}

.about-founder h6 {
  font-size: 26px;
  margin-top: 1rem;
}

.about-founder-content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 2rem 0;
  background-color: #f3f3f3;
  border-radius: 10px;
  box-shadow: 0 0 10px lightblue;
}

.about-founder-picture {
  display: flex;
  justify-content: center;
}

.about-founder-text {
  display: flex;
  padding: 40px 20px;
  
}

.about-founder-text p {
  line-height: 1.5;
  font-size: 18px;
}

.about-founder-content-img {
  width: 550px;
  height: auto;
  object-fit: contain;
}



@media screen and (max-width: 960px) {
  .aboutUsContainer {
    padding: 6rem 8px 4rem 8px;
  }

  .why-us-section {
    display: flex;
    flex-direction: column;
  }

  .why-us-section img {
    margin: 0;
  }


  .about-founder {
    margin: 30px 0px;
    padding: 0 10px;
  }
  .about-founder-content {
    flex-direction: column;
  
  }

  .about-founder-content-img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
