.return-policy {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    justify-content: center;
}

.return-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding: 6rem 0 4rem 0;
}

.return-container h4 {
    margin: 1rem 0;
    font-size: 32px;
}

.return-container h5 {
    margin: 1rem 0 0 0;
    font-size: 24px;
}

.return-container p {
    margin: 1rem 0;
    font-size: 20px;
}