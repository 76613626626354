.clients {
  width: 100%;
  height: 25rem;
  display: flex;
  background-color: #f5f7fa;
  justify-content: center;
  align-items: center;
}

.clients-container {
  width: 100%;
  height: 20rem;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2rem 0;
}

.clients-container-mobile {
  display: none;
}

.clients-container h5 {
  font-size: 25px;
  margin-bottom: 18px;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

.carousel-container {
  display: flex;
  padding: 2rem 0;
}

.clients-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 150px;
  overflow: hidden;
}

.client-logo {
  width: auto;
  max-width: 180px;
  height: auto;
  max-height: 130px;
  margin: 0 30px;
  object-fit: contain;
}

@media screen and (max-width: 960px) {
  .clients-container-mobile {
    width: 100%;
    height: 20rem;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
  }

  .clients-container {
    display: none;
  }

  .clients-container-mobile h5 {
    font-size: 22px;
    margin-bottom: 18px;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
  }

  .client-logo {
    width: auto;
    max-width: 150px;
    height: auto;
    max-height: 130px;
    margin: 0 15px;
    object-fit: contain;
  }
}
