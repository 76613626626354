.emailForm-container {
    display: flex;
    justify-content: center;
    
  }
  
  .emailForm {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    width: 600px;
    height: 500px;
    padding: 30px 30px;
    border-radius: 5px;
  }
  
  
  textarea {
    border-radius: 2px;
    /* box-shadow: 1px 1px grey; */
  }
  textarea:hover {
    background-color: aliceblue;
  }
  
  input {
    height: 35px;
    border-radius: 2px;
    border-width: 0.1px;
    /* box-shadow: 1px 1px grey; */
  }
  
  input:hover {
    background-color: aliceblue;
  }
  .submit-btn {
    margin-top: 18px;
    width: 130px;
    height: 30px;
    margin-bottom: 10px;
    background-color: rgb(17, 17, 39);
    color: white;
    border-radius: 5px;
  }
  
  label {
    margin: 10px 0;
  }
  
  @media screen and (max-width: 960px) {
    .emailForm {
      width: 320px;
      margin: 1rem 0;
      height: 500px;
      
    }
  }
  