.hero-btn {
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  background: #9e0404;
  color: #fff;
  border: 1px solid #9e0404;
  font-weight: 200;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 1px;
  margin-top: 1rem;
}

.mask {
  width: 100%;
  height: 100%;
  position: relative;
}

.mask::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.3;
}

.carousel-caption {
  padding-bottom: 0;
}

.carousel-caption h3 {
  margin-bottom: 0;
}
