.btn-large {
  padding: 0.5rem 1rem;
  width: 100%;
  text-transform: uppercase;
  background: #9e0404;
  color: #fff;
  border: 1px solid #9e0404;
  font-weight: 300;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 1px;
  margin-top: 40px;
}

/* Styles for when the product is not found */
.not-found {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  font-size: 2rem;
}

/* General styles for the product details page */
.productDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f9f9f9;
}

.productDetails-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  padding: 4rem 1rem;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 1rem;
}

/* Styles for the product image section */
.productImage {
  flex: 1;
  flex-direction: column;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.productImage img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.productImage a {
    width: 100%;
}

/* Styles for the product brief section */
.productBrief {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
}

.productBrief h5 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333333;
}

.productBrief h6 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #666666;
}

.productBrief p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #555555;
  line-height: 1.6;
}

.productBrief ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
}

.productBrief ul li {
  margin-bottom: 0.5rem;
  color: #444444;
}

.productBrief ul li strong {
  color: #000000;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .productDetails-container {
    flex-direction: column;
    align-items: center;
  }

  .productImage {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .productBrief {
    align-items: center;
    text-align: center;
    padding: 0;
  }

  .productBrief ul {
    padding-left: 0;
  }
}
