.contact {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    justify-content: center;
}

.contact-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding: 6rem 0 4rem 0;
}

.contact-container h2 {
    font-size: 32px;
}

.contact-container-form {
    margin: 2rem 0;
    padding: 2rem 0;
}

.contact-container-map {
    width: 100%;
    max-width: 1200px;
    padding: 4rem 0;
}

@media screen and (max-width:960px) {
    .contact-container h2 {
        margin-left: 8px;
    }
}