.terms-and-conditions {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    justify-content: center;
    
}

.terms-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding: 6rem 0 4rem 0;
}

.terms-container h4 {
    font-size: 32px;
}

.terms-container ul {
    margin: 2rem 0 2rem 0;
}

.terms-container ul li {
    margin: 1rem 0;
    font-size: 20px;
}