.header {
    width: 100%;
    display: flex;
    background-color: #000;
    height: 15rem;
    /* border-bottom: 1px solid white; */
    justify-content: center;
    align-items: center;
}

.header h1 {
    color: #fff;
}