.navbar {
  width: 100%;
  background-color: #ffffff;
  align-items: center;
  height: 6rem;
}

.navbar-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0 0.5rem 0;
}

.navbar-content-logo {
  padding-left: 2rem;
}

.navbar-content-list {
  display: flex;
  width: 55rem;
  justify-content: center;
}

.navbar-content-list ul {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.navbar-content-list a{
 text-decoration: none;
 color:#000000
}

.navbar-content-list ul li {
  margin: 0 2rem 0 2rem;
  font-family: sans-serif;
}

.navbar-production-button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.navbar-production-button:hover {
  background-color: #9e0404;
}

.navbar-contact {
  display: flex;
  padding: 0 1rem 0 2rem;
  align-items: center;
}

.navbar-contact a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #000000;
}

.navbar-contact-details {
  font-size: 10px;
  margin: 0 5px 0 5px;
}

.side-drawer {
  position: fixed;
  top: 96px;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: black;
  color: white;
  z-index: 1000;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.hamburger-btn {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 200px;
}

/* .side-menu {
  display: flex;
  justify-content: center;
  margin-top: 200px;
} */

.side-drawer ul {
  list-style-type: none;
  padding: 20px;
}

.side-drawer ul li {
  margin: 20px 0;
  font-size: 18px;
  color: #ffffff;
}

.side-drawer a {
  color: white;
  text-decoration: none;
}

.hamburger {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 960px) {

  .navbar-content {
    justify-content: space-between;
    
  }

  .navbar-content-list {
    display: none;
  }

  .navbar-content-logo {
    padding-left: 0;
  }

  .navbar-button {
    display: none;
  }

  .navbar-contact-details {
    display: none;
  }

  .navbar-contact {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    
  }

  .hamburger {
    display: flex;
    margin-right: 15px;
  }
}