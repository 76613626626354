.hero-btn-small {
  padding: 0.4rem 0.8rem;
  text-transform: uppercase;
  background: #9e0404;
  color: #fff;
  border: 1px solid #9e0404;
  font-weight: 200;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: 1px;
  margin-top: 1rem;
}

.featured-products {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  justify-content: center;
}

.featured-products-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  padding: 2rem 0;
}

.featured-products-container-mobile {
  display: none;
}

.featured-products-container h5 {
  font-size: 25px;
  margin-bottom: 18px;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

.card-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-img-top {
  height: 25rem;
  padding: 4px;
  background-color: #1f1f1f;
}

.card-body {
  padding: 0.5rem 1rem;
  background-color: #1f1f1f;
}

.card-title {
  margin: 0;
  font-size: 18px;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .featured-products-container {
    display: none;

  }

  .featured-products-container-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    padding: 2rem 1rem;
  }

  .featured-products-container-mobile h5 {
    font-size: 22px;
    margin-bottom: 18px;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
  }

  


  
}
