.testimonial {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  justify-content: center;
  height: auto;
}

.testimonial-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
 
}

.testimonial-container h5 {
  font-size: 25px;
  margin: 0;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  width: 215px;
}

.testimonial-carousel {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.testimonial-box {
    display: flex;
    flex-direction: column;
    background-color: #F1F1F1;
    padding: 2rem 5rem;
    margin: 2rem 0;
    border: 1px solid black;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 850px;
    border-radius: 10px;
    height: 260px;
}

.testimonial-box p {
    font-family: "Playwrite NZ";
    line-height: 2;
    margin-bottom: 30px;
}

.carousel-indicators {
    filter: invert(100%);
    margin-bottom: 0;
}

@media screen and (max-width: 960px) {
  .testimonial-container {
    align-items: center;
  }

  .testimonial-box {
    width: 100%;
    max-width: 330px;
    height: 400px;
    padding: 1rem 2rem;
  }
}




