.damage-policy {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  justify-content: center;
}

.damage-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 6rem 0 4rem 0;
}

.damage-container h4 {
  font-size: 32px;
  margin: 1rem 0;
}

.damage-container p {
  margin: 1rem 0;
  font-size: 20px;
}
