.productCatalogCamera {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.productCatalogCamera h5 {
  font-size: 25px;
  margin-bottom: 18px;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  margin-left: 1rem;
  width: 100px;
}

.cardContainerCamera {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 1rem;
}

.product-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0 2rem 0;
}

.pagination {
  margin: 0;
}

@media screen and (max-width:960px) {
  .cardContainerCamera {
    margin: 0
  }
}

