.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    /* background-color: #25d366; */
    border-radius: 25%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-decoration: none; /* To remove underline in anchor tag */
  }
  
  .whatsapp-logo {
    width: 70px; /* Adjust the logo size to fit within the button */
    height: 70px;
  }