.productCatalogDrone {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.productCatalogDrone h5 {
    font-size: 25px;
    margin-bottom: 18px;
    border-bottom: 1px solid black;
    padding-bottom: 5px;
    margin-left: 1rem;
    width: 80px;
  }

.cardContainerDrone {
    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 1rem;
}

@media screen and (max-width:960px) {
    .cardContainerDrone {
        margin: 0;
    }
}