.productCatalog {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #f5f7fa;
}

.productCatalog-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  margin: 4rem 0;
}

.accordion {
  width: 100%;
  max-width: 300px;
}

.accordion-button {
  font-size: 20px!important;
}

.accordion ul {
  list-style: none;
  

}

.accordion ul a {
  text-decoration: none;
}

.accordion ul li {
  padding: 0.5rem 0;
  font-size: 18px;
  color: #000;
  
}

.cardContainer {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 1rem;
}

.product-card {
  margin: 5px 5px;
  width: 100%;
  max-width: 335px;
}

.product-card-image {
  background-color: #fff;
  width: 330px;
  height: 400px;
  object-fit: contain;
  padding: 2px 10px;
}

.product-catalog {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 1rem;
}

@media screen and (max-width:960px) {
  .accordion {
    display: none;
  }

  .product-card {
   max-width: 152px;
   height: 350px;
   
  }
  .product-card-image {
    width: 152px;
    padding: 2px 5px;
    height: 260px;
  }

 

}